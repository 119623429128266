@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css?family=Allerta+Stencil");
@import url("https://fonts.googleapis.com/css?family=Anton");

*::-webkit-scrollbar:hover {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: rgba(90, 89, 89, 0.75);
}

*::-webkit-scrollbar-thumb {
  background-color: #27272b;
}

* {
  /* background-image: url(../image/div.png); */
  font-family: "Roboto", "Arial Narrow", Arial, sans-serif;
}

/* mui menu */
/* .MuiMenu-root ul {
  background-color: rgba(0, 0, 0, 0.78);
  color: white;
} */

.pixi-overlay {
  z-index: 10000;
}

.tabl-flex-admin-user {
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  background: rgba(90, 89, 89, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.01);
  padding: 0px 10px;
  box-shadow: 0 4px 30px rgba(35, 35, 35, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
}
.basesTableCell {
  background: none;

  border: 1px solid rgb(0, 0, 0);
  padding: 0 10px;
}

.tableInput {
  background: none;
  color: rgb(0, 0, 0);
  border: none;
  text-align: center;
}

.adminChats {
  overflow: scroll;
  overflow: overlay;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  max-height: 85vh;
  min-height: 80vh;
}
.mainChat {
  overflow: scroll;
  overflow: overlay;
  overflow-x: hidden;
  max-height: 400px;
}
.scrollDiv {
  overflow: scroll;
  overflow: overlay;
  overflow-x: hidden;
  max-height: 70vh;
}
.scrollDiv::-webkit-scrollbar {
  width: 1rem !important;
  background: rgb(218, 218, 218);
}
.scrollDiv::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0);
}

.styleAdminPanel {
  background: rgb(30, 30, 30);
  /*background: url(../image/girlAnime.jpg) center fixed;*/
  /* background: url(../image/wallpaperflare.com_wallpaper-2.jpg) center fixed;
  background-size: cover; */
}

.fileInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.paperPlan {
  transition: all 0.5s !important;
}

.paperPlan:hover {
  transition: all 0.5s !important;
  transform: skew(30deg);
}

.buttonDeal1 {
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  background: rgb(45, 182, 18);
  border: 1px solid rgb(49, 195, 20);
  padding: 5px 10px;
  text-align: center;
  box-shadow: 0 4px 30px rgba(35, 35, 35, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
  max-width: 220px;
  cursor: pointer;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.879);
}

.buttonDeal2 {
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  background: rgb(217, 20, 20);
  border: 1px solid rgb(217, 20, 20);
  padding: 5px 10px;
  text-align: center;
  box-shadow: 0 4px 30px rgba(35, 35, 35, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
  max-width: 220px;
  cursor: pointer;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.879);
}

.flex-box-2,
.flex-box-1,
.flex-box-0 {
  max-width: 850px;
}

.buttonDeal2:hover {
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  background: rgb(236, 21, 21);
  border: 1px solid rgb(236, 21, 21);
  padding: 5px 10px;
  text-align: center;
  box-shadow: 0 4px 30px rgba(35, 35, 35, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
  max-width: 220px;
  cursor: pointer;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.879);
}

.buttonDeal1:hover {
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  background: rgb(52, 209, 20);
  border: 1px solid rgb(52, 209, 20);
  padding: 5px 10px;
  text-align: center;
  box-shadow: 0 4px 30px rgba(35, 35, 35, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
  max-width: 220px;
  cursor: pointer;
  border-radius: 5px;
  color: rgb(255, 255, 255);
}

.fileInput + label {
  height: 41px;
  width: 49px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
  background-color: white;
}

.posishnNameButton {
  display: flex;
  justify-content: space-between;
}

.fileInput + label:hover {
  transition: 0.5s;
  background-color: black;
  color: white;
}

.styleAdminPanel2 {
  background: linear-gradient(122deg, #48788d, #83a8c7, #80bcce, #75b9e0);
  /* background: url(../image/wallpaperflare.com_wallpaper-4.jpg) center fixed;
  background-size: cover; */
}

.tabl-flex-admin {
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  background: rgba(90, 89, 89, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.01);
  padding: 10px;
  box-shadow: 0 4px 30px rgba(35, 35, 35, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  margin: 0;
}

.tabl-flex-admin-filtr {
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  background: rgba(90, 89, 89, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.01);
  padding: 5px 10px 5px 10px;
  box-shadow: 0 4px 30px rgba(35, 35, 35, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
}

/* .MuiPaginationItem-text {
  background-color: rgba(90, 89, 89, 0.75) !important;
} */
/* .MuiPaginationItem-text:hover {
  background-color: rgba(144, 229, 229, 0.696) !important;
  transition: 0.3s;
  transform: scale(1.06);
} */

/* .Mui-selected {
  background-color: rgba(236, 232, 232, 0.597) !important;
} */

.tabl-flex-admin-pages {
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  background: rgba(90, 89, 89, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.01);
  padding: 5px 0px;
  text-align: center;
  box-shadow: 0 4px 30px rgba(35, 35, 35, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
  max-width: 70px;
}

.tabl-flex-admin-user-scores {
  cursor: pointer;
  -webkit-inner-spin-button: none;
  -webkit-outer-spin-button: none;
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  background: rgba(90, 89, 89, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.01);
  padding: 5px 0px;
  text-align: center;
  box-shadow: 0 4px 30px rgba(35, 35, 35, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
  min-width: 230px;
}

.pages-user-block {
  display: flex;
  gap: 1rem;
  margin-top: 10px;
  color: white;
  /* backdrop-filter: blur(5.5px); */
  padding: 5px;
  border: 0.5px solid rgba(90, 89, 89, 0.75);
  flex-direction: column;
}

.pages-user-box {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 1070px;
}

.pages-user-box-2 {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 1rem;
}

.tabl-flex-admin-button {
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  background: rgba(90, 89, 89, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.01);
  padding: 5px 10px;
  text-align: center;
  box-shadow: 0 4px 30px rgba(35, 35, 35, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
  max-width: 100px;
  cursor: pointer;
  border-radius: 5px;
}

.modalStyles {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(17, 17, 18, 0.65);
  display: flex;
  justify-content: center;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}
.modalContentStyles {
  padding: 2rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.tabl-flex-admin-button-global {
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  background: rgba(90, 89, 89, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.01);
  padding: 10px 10px;
  text-align: center;
  box-shadow: 0 4px 30px rgba(35, 35, 35, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
  max-width: 150px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s;
  color: white;
}

.tabl-flex-admin-masseges {
  padding: 0.375rem 0.75rem;
  border: 0.5px solid rgba(90, 89, 89, 0.75);
  width: 80%;
  margin-right: 1px;
  border-radius: 0px 0px 0px 4px;
  background: rgba(90, 89, 89, 0.75);
  color: #202020da;
  font-size: 1rem;
  transition: 0.3s;
}

:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}

.buttonAdminChat:hover {
  color: #000;
  transition: all 0.3s;
  transform: scale(1.05);
}

.buttonAdminChat:active {
  transition: all 0.2s;
  transform: translateY(-6px);
}

.buttonAdminChat {
  width: 192px;
  border: 0.5px solid rgba(90, 89, 89, 0.75);
  background: rgba(90, 89, 89, 0.75);
  border-radius: 0px 0px 4px 0px;
  position: absolute;
  bottom: 0px;
  height: 43px;
  color: white;
  transition: all 0.3s;
}

.massegeStyleAdminChat p {
  margin: 0;
}

.massegeStyleUserChat {
  font-size: 1rem;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
}

.massegeStyleUserChatLocation {
  font-size: 15px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 15px;
}

.styleSizeChat {
  font-size: 22px;
}

.flex-pos {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.boxStyle1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.boxStyle2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.massegeStyleAdminChat {
  font-size: 1rem;
  color: white;
  display: flex;
  flex-direction: column;
  flex-direction: row-reverse;
  padding: 5px 15px;
}

.posMassegeses {
  color: cadetblue;
  font-size: 15px;
}

.blockMassegesAdminPanel {
  margin-bottom: 1rem;
  border-radius: 4px;
  margin-top: 1rem;
  backdrop-filter: blur(5.5px);
  border: 0.5px solid rgba(90, 89, 89, 0.75);
  color: white;
  width: 967px;
  height: 100vh;
  position: relative;
}

.css-1prob85 {
  width: 45px !important;
  height: 45px !important;
  color: white !important;
}

.tabl-flex-admin-button-global2 {
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  background: rgba(90, 89, 89, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.01);
  padding: 10px 10px;
  text-align: center;
  box-shadow: 0 4px 30px rgba(35, 35, 35, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
  max-width: 165px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s;
}

.tabl-flex-admin-button-global3 {
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  background: rgba(90, 89, 89, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.01);
  padding: 10px 10px;
  text-align: center;
  box-shadow: 0 4px 30px rgba(35, 35, 35, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
  max-width: 175px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s;
  color: whitesmoke;
}

.tabl-flex-admin-button-global3:hover {
  color: rgb(49, 241, 49);
  transition: all 0.3s;
  transform: scale(1.05);
}

.tabl-flex-admin-button-global2:hover {
  color: rgb(49, 241, 49);
  transition: all 0.3s;
  transform: scale(1.05);
}

.tabl-flex-admin-button-global:hover {
  color: rgb(255, 43, 43);
  transition: all 0.3s;
  transform: scale(1.05);
}

.tabl-flex-admin-button-changes {
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  background: rgba(90, 89, 89, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.01);
  padding: 5px 10px;
  text-align: center;
  box-shadow: 0 4px 30px rgba(35, 35, 35, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
  max-width: 200px;
  cursor: pointer;
  border-radius: 5px;
}

.tabl-flex-admin-button-changes:hover {
  color: rgb(133, 255, 67);
  transition: 0.3s;
  transform: scale(1.05);
}

.tabl-flex-admin-button:hover {
  color: red;
  transition: 0.3s;
  transform: scale(1.05);
}

.tabl-flex-admin-search {
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  background: rgba(90, 89, 89, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.01);
  box-shadow: 0 4px 30px rgba(35, 35, 35, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
  height: 44px;
}

.promo-description {
  width: 1110px;
  background: rgba(17, 17, 18, 0.65);
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 1rem;
  padding: 15px;
  margin-top: 1rem;
  border-radius: 1rem;
}

.container-body-v1 {
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgba(17, 17, 18, 0.65);
  flex-direction: column;
  align-items: center;
  font-family: "Roboto Condensed", "Arial Narrow", Arial, sans-serif;
}

.block-main-v1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block-main-v1 h1 {
  font-size: 30px;
  line-height: 42px;
  color: #f9f5e1;
  margin-bottom: 15px;
  word-wrap: break-word;
  font-family: inherit;
  font-weight: 500;
}

.btn-class-v1:hover {
  background: #fff;
  border-color: #fff;
  color: #191e23;
  transform: translateY(6px);
}

.btn-class-v3:hover {
  background: #fff;
  border-color: #fff;
  color: #191e23;
}

.btn-class-v3 {
  color: #f25322;
  box-shadow: none;
  background: 0 0;
  border: 2px solid #f25322;
  transition: all ease 0.5s;
  font-family: "Roboto Condensed", "Arial Narrow", Arial, sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 0 -1px rgba(71, 0, 0, 0.3);
  box-sizing: border-box;
  height: 44px;
  min-width: 180px;
  padding: 0 35px;
  border-radius: 1rem;
}

.btn-class-v1 {
  color: #f25322;
  box-shadow: none;
  background: 0 0;
  border: 2px solid #f25322;
  transition: all ease 0.5s;
  font-family: "Roboto Condensed", "Arial Narrow", Arial, sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 0 -1px rgba(71, 0, 0, 0.3);
  box-sizing: border-box;
  height: 44px;
  min-width: 180px;
  padding: 0 35px;
  border-radius: 1rem;
}

.promo-block-text {
  margin-top: 1rem;
  text-align: left;
  background: #202020;
  border-left: #59dbff 5px solid;
}

.promo-block-text-center {
  padding: 13px 18px 13px 23px;
  color: #c0c0c0;
  font-size: 16px;
  margin: 0;
}

.ist-promo {
  padding: 0;
  list-style: none;
  margin: 36px auto 0;
  text-align: center;
}

.list-promo-item {
  display: inline-block;
  padding: 30px 0 0;
  min-width: 360px;
  width: 33%;
  vertical-align: top;
  white-space: nowrap;
  color: #b8b8a2;
}

.list-promo-item p {
  font-size: 17px;
}

.promo-steps {
  position: relative;
  margin: 80px 0 0 0;
  padding: 15px;
  background: rgba(17, 17, 18, 0.65);
  color: #f9f5e1;
  width: 1110px;
  margin-bottom: 100px;
  border-radius: 1rem;
}

.promo-steps h3 {
  text-align: center;
}

.list-steps {
  margin: 0;
}

.promo-steps .step {
  padding-bottom: 30px;
  list-style: none;
  display: flex;
  align-items: center;
}

.promote-slider__showcase {
  width: 100%;
  background-color: rgba(17, 17, 18, 0.65) !important;
  padding: 30px 0;
  margin-bottom: 90px;
  font-family: "Roboto Condensed", "Arial Narrow", Arial, sans-serif;
}

.promote-slider {
  position: relative;
}

.promote-slider_item_list {
  display: flex;
}

.info-card {
  float: left;
  width: 257px;
  margin-left: 35px;
  margin-bottom: 1rem;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.info-card .card-header {
  background-color: #3e5e80;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  font-family: "Roboto Condensed", "Arial Narrow", Arial, sans-serif;
  display: flex;
  align-items: center;
  height: 75px;
}

.promote-slider_holder {
  display: flex;
  justify-content: center;
}

.link-hover-effects {
  color: #f25322;
  text-decoration: none;
  transition: all 0.3s ease-out;
}

.link-hover-effects:hover {
  color: #fd7e14;
}

.info-card .card-header .card-header-text {
  position: relative;
  line-height: 50px;
  font-size: 1rem;
  color: #fff;
  padding: 15px 0;
  font-weight: 500;
  font-family: "Roboto Condensed", "Arial Narrow", Arial, sans-serif;
}

.promote-slider_list {
  margin-left: -52px;
}

.info-card .card-body {
  background-color: #191e23;
  padding: 15px;
  font-size: 15px;
  color: #a9aeb3;
  font-size: 16px;
  font-family: "Roboto Condensed", "Arial Narrow", Arial, sans-serif;
}

.block-text-free {
  margin-bottom: 150px;
  font-family: "Roboto Condensed", "Arial Narrow", Arial, sans-serif;
}

.text-free {
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.5rem;
  color: #b8b8a2;
}

.promote-slider__showcase {
  background-color: rgba(17, 17, 18, 0.65);
  padding: 30px 0;
  margin-bottom: 90px;
}

.container {
  max-width: 1110px;
}

.promote-slider_list {
  display: flex;
}

.promote-slider_item {
  padding-left: 28px;
  overflow: hidden;
}

.showcase {
  overflow: hidden;
  display: block;
  position: relative;
}

.show-img-1 {
  width: 330px;
  height: 187px;
}

.showcase_pic {
  opacity: 0.5;
  -webkit-transition: ease 0.5s;
  transition: ease 0.5s;
}

.showcase_pic:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.show-img-1:hover {
  transform: scale(1.1);
}

.showcase_title {
  color: #e9e2bf;
}

.showcase_info {
  position: absolute;
  top: 15px;
  left: 1rem;
  right: 1rem;
  z-index: 2;
}

.bg-img {
  /* background-image: url(../image/div.png); */
  /* background-image: url(../image/girl.jpeg); */
  background-color: #191919;
  font-family: "Roboto Condensed", "Arial Narrow", Arial, sans-serif;
}

.icon-chat-sizes {
  width: 60px !important;
  height: 60px !important;
}

.icon-chat-sizes2 {
  width: 60px !important;
  height: 60px !important;
  bottom: -8px;
  right: -7.5px;
}

.css-11pbyhm-MuiSvgIcon-root {
  color: white !important;
  width: 45px !important;
  height: 45px !important;
}

.chat-icon {
  color: aliceblue !important;
  position: fixed !important;
  z-index: 100;
  bottom: 1.4285714286em;
  right: 1.4285714286em;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 5px 38px rgb(0 0 0 / 15%);
  box-shadow: 0 5px 38px rgb(0 0 0 / 15%);
  cursor: pointer;
  -webkit-transition: visibility 0s linear 0.2s, opacity linear 0.2s;
  -o-transition: visibility 0s linear 0.2s, opacity linear 0.2s;
  transition: visibility 0s linear 0.2s, opacity linear 0.2s;
  transition: ease 0.5s !important;
  background: rgba(90, 89, 89, 0.75);
}

.chat-icon:hover {
  transform: scale(1.1);
}

.wraper {
  width: 1110px;
  margin-top: 30px;
  margin-bottom: 103px;
  margin-right: 146px;
  margin-left: 163px;
}

.login-inner_title {
  font-size: 30px;
  line-height: 42px;
  margin-bottom: 15px;
  word-wrap: break-word;
}
.hr-viss {
  border: 0;
  height: 1px;
  background-color: #59595c;
  margin: 0 0 16px;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1140px !important;
}

.width-form {
  width: 720px;
}

.color-input-name {
  color: #fff;
}

.btn-class-v2:hover {
  background: #fff;
  border-color: #fff;
  color: #191e23;
  cursor: pointer;
}

.btn-class-v2 {
  color: #f25322;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: 0 0;
  border: 2px solid #f25322;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  font-family: "Roboto Condensed", "Arial Narrow", Arial, sans-serif;
  display: inline-flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 0 -1px rgba(71, 0, 0, 0.3);
  border-radius: 1px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 44px;
  width: 110px;
  padding: 0 35px;
  white-space: nowrap;
}

.page-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 50% 0;
  margin-bottom: -103px;
}

.page-wrapper .page-wrapper_inner {
  width: 100%;
  min-height: 100vh;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.docs-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  text-align: center;
  display: block;
  color: #595959;
  /* background-image: url(../image/div.png); */
}

.docs-popup-wrapper {
  width: 90%;
  height: 90%;
  min-width: 290px;
  min-height: 290px;
  max-width: 1000px;
  background-color: #fff;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
  padding: 1rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  text-align: left;
  overflow: auto;
  margin-top: 40px;
}

.tytleStaleComents {
  display: flex;
  justify-content: center;
  color: #f9f5e1;
  padding: 1rem 0;
}

.card-header-coments {
  background-color: #3e5e80;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  font-family: "Roboto Condensed", "Arial Narrow", Arial, sans-serif;
  display: flex;
  align-items: center;
  height: 75px;
}

.containerCard {
  max-width: 257px;
}

.carousel-indicators {
  display: none !important;
}

.container-card-swap {
  width: 1230px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: visible;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.carousel-item {
  gap: 1rem;
  display: flex !important;
  justify-content: center !important;
}

.active {
  justify-content: center;
  transform: all 1s;
}

.container-allCard {
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: content-box;
  overflow: hidden;
  gap: 30px;
  transition: all 1s !important;
}

.css-iljtu2-MuiRating-root.Mui-disabled {
  opacity: 1 !important;
}

.card-header-text-coments {
  position: relative;
  line-height: 50px;
  font-size: 1rem;
  color: #fff;
  padding: 15px 0;
  font-weight: 500;
}

.card-body-coments {
  background-color: #191e23;
  padding: 15px;
  font-size: 15px;
  color: #a9aeb3;
  font-size: 16px;
}

.docs-popup-wrapper-title {
  color: #383838;
  font-size: 30px;
  line-height: 30px;
  font-weight: bold;
  text-transform: uppercase;
  padding-right: 18px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.docs-popup-wrapper-description {
  height: calc(100% - 40px);
  overflow: hidden;
  padding: 0px;
  width: 960px;
  text-align: left;
  font-size: 14px;
  line-height: 1.5;
  overflow: hidden;
  overflow-y: scroll;
}

.jspContainer {
  overflow: hidden;
  position: relative;
}

.jspPane {
  padding: 0px;
}

.page-container {
  background-color: #2d2d2d;
  padding-bottom: 1rem;
}

.page-container-2 {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.main-section__banner {
  padding: 39% 0 0 0;
  overflow: hidden;
  position: relative;
}

.main-section__heading {
  position: relative;
  width: 100%;
  transform: translateY(-50%);
  left: 18px;
  max-width: 500px;
  z-index: 2;
  background: #1e1d1d;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  overflow: hidden;
  padding: 10px 35px 1rem;
  color: #fff;
  font-size: 1rem;
  line-height: 1.2;
  text-align: center;
}

.step-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 750px;
  margin: 0 auto;
  padding-bottom: 50px;
  color: #fff;
}

.step-wrap__item {
  max-width: 190px;
  width: 100%;
  margin: 0 0 30px;
}

.card-sert {
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
}

.card-sert__number {
  display: inline-block;
  vertical-align: top;
  line-height: 1;
  background-color: #1e1d1d;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #a9a3a3;
  padding: 2px 15px;
  margin: 0 0 6px;
}

.card-sert__img-wrap {
  border: 2px solid #a9a3a3;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 10px;
  background-color: #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.status-wrap {
  padding: 0 15px;
}

.status-wrap__heading {
  font-size: 1rem;
  line-height: 1.2;
  color: #fff;
  max-width: 870px;
  margin: 0 auto 25px;
  padding: 25px 15px;
  text-align: center;
  background-color: #1e1d1d;
  border-radius: 10px;
}

.status-wrap__info-list {
  margin: 0 auto 60px;
}

.info-list {
  padding: 15px;
  background-color: #1e1d1d;
  max-width: 946px;
  margin: 0 auto;
  border-radius: 1rem;
}

.card-info {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  font-size: 14px;
  line-height: 1.2;
  color: #000;
  display: flex;
  overflow: hidden;
}

.card-info__heading {
  flex: 0 0 210px;
  text-align: center;
  background-color: #d3d8e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-info__img-wrap {
  margin: 0 0 8px;
}

.info-list__item:not(:first-child) {
  padding-top: 15px;
}

.protection-period__heading {
  font-size: 1rem;
  line-height: 1.2;
  color: #fff;
  padding: 25px 15px;
  max-width: 870px;
  background-color: #1e1d1d;
  border: 2px solid #a9a3a3;
  border-radius: 10px;
  text-align: center;
  margin: 70px auto;
}

.protection-period__description {
  font-size: 14px;
  line-height: 1.2;
  max-width: 930px;
  margin: 0 auto 60px;
  border: 2px solid #a9a3a3;
  border-radius: 1rem;
  background-color: #ededed;
  padding: 10px 105px;
  color: #1e1d1d;
}

.banner-info {
  padding: 1rem 10px;
}

.banner-info__img-wrap {
  max-width: 720px;
  margin: 0 auto -55px;
}

.banner-info__description {
  padding: 10px 25px;
  font-size: 1rem;
  line-height: 1.2;
  color: #fff;
  max-width: 490px;
  background-color: #1e1d1d;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.card-info__content {
  flex-grow: 1;
  min-height: 104px;
  padding: 1rem 45px;
  display: flex;
  align-items: center;
}

.main-section--about .main-section__heading {
  padding-top: 1rem;
}

.img-cover {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.docs-popup-wrapper-description::-webkit-scrollbar-thumb {
  background-color: #fe1f1a;
  border-radius: 4px;
  background: none;
}

.hovet-effect {
  width: 70px !important;
  height: 70px !important;
  transition: 0.3s !important;
}

.hovet-effect:hover {
  transform: translateY(-8px);
  color: #f1f8fa;
}

.name-add_sell {
  border-color: rgba(255, 255, 255, 0.1);
  border-style: solid;
  border-width: 1px 0;
  background: rgba(0, 0, 0, 0.7);
  margin-bottom: 30px;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
}

.wrap {
  margin: 30px 146px 103px 146px !important;
  max-width: 1111px !important;
  min-height: calc(100vh - 339px);
}

.form-size-flex {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding-bottom: 15px;
  border-bottom: 2px solid;
}

.form-select {
  width: 80% !important;
  margin-bottom: 0.5rem;
}

.inpu-summary {
  width: 50% !important;
}

.spec-btn-ux {
  height: 45px;
  width: 150px;
  border-radius: 10px;
  background: #e48731;
  border: 2px;
  color: white;
}

.trades-table {
  width: 100%;
  font-size: 14px;
  line-height: 1.2;
  color: #fff;
  margin-top: 40px;
}

.disputes-wrap {
  margin: 0 0 40px;
}

.section-check-info {
  background-color: #ededed;
  border: 2px solid #a9a3a3;
  border-radius: 1rem;
  overflow: hidden;
  max-width: 930px;
  margin: 0 auto 25px;
  padding: 15px 70px;
}

.list-clip {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.trades-table-deals {
  width: 100%;
  font-size: 14px;
  line-height: 1.2;
  color: #fff;
  margin-top: 40px;
}
.trades-table-deals tr th {
  font-weight: 700;
  text-align: center;
  padding: 15px 10px;
}

.trades-table tr th {
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  background-color: #2e2d2d;
  padding: 15px 10px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.body-chat-sms {
  display: flex;
  position: absolute;
  bottom: 0px;
  justify-content: space-around;
  width: 100%;
}

.account-wrap__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 70px;
  font-size: 1rem;
  line-height: 1.2;
  color: #fff;
  padding: 0 55px 10px 15px;
  border-bottom: 1px solid #a9a3a3;
  padding-top: 25px;
  margin-top: 30px;
}

.account-wrap__heading h2 {
  font-weight: 700;
  font-size: 22px;
}

.sum-account {
  display: flex;
  align-items: center;
}

.sum-account__label {
  margin: 0 40px 0 0;
}

.btn-orange {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
  border: 2px solid #e48731;
  padding: 12px 35px;
  background-color: #1e1d1d;
  text-transform: uppercase;
  transition: all ease 0.5s;
  cursor: pointer;
  color: #e48731;
  text-decoration: none;
  border-radius: 10px;
}

.btn-orange:hover {
  background-color: #e48731;
  color: white;
}

.nav-account {
  display: flex;
  margin-bottom: 30px;
  gap: 10px;
}

.nav-account__tabs {
  flex: 0 0 260px;
}

.nav-account__link {
  display: block;
  cursor: pointer;
  transition: all ease 0.5s;
  font-size: 1rem;
  line-height: 1;
  padding: 15px 10px;
  text-align: center;
  background-color: #2e2d2d;
  color: #fff;
  border: 2px solid #2e2d2d;
  border-radius: 10px;
  text-align: center;
  width: 260px;
}

.activ-link {
  background-color: #515050;
}

.nav-account__link:not(:first-child) {
  margin-top: 1rem;
}

.nav-account__link:hover {
  background-color: #515050;
}

.nav-account__content {
  border: 2px solid #2e2d2d;
  padding: 15px 55px;
  background-color: #1e1d1d;
  border-radius: 10px;
  margin-left: 2px;
}

.operation {
  font-size: 14px;
  line-height: 1.2;
  color: #fff;
}

.operation__heading {
  text-align: center;
  margin: 0 0 30px;
}

.form-operation {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 0 0 35px;
}

.form-operation__item {
  display: flex;
  align-items: center;
  margin: 0 0 1rem;
  gap: 10px;
}

.inputLable {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
}

.account-wrap__about-info {
  max-width: 590px;
  padding: 1rem;
  border: 2px solid #e48731;
  color: #fff;
  background-color: #1e1d1d;
  margin: 0 auto 45px;
  font-size: 1rem;
  line-height: 1.2;
  margin-top: 30px;
}

.account-wrap__time-info {
  text-align: center;
  color: #fff;
  font-size: 1rem;
  line-height: 1.2;
}

.alert-block_info {
  border-left: #36d7b7 5px solid;
}

.alert-block {
  margin: 1rem 0;
  text-align: left;
  background: #202020;
}

.text-alert {
  font-size: 16px;
  line-height: 150%;
  padding: 13px 18px 13px 23px;
  color: #c0c0c0;
}

.flex-box-0 {
  display: flex;
  flex-direction: column;
}

.account-wrap__time-info {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  color: #fff;
  font-size: 1rem;
  line-height: 1.2;
}

.output-description-info-block {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #f25322;
  color: #fff;
  padding: 6px;
}

.output-description-info-block p {
  margin: 0;
}

.flex-box-0 {
  min-height: calc(100vh - 200px);
}

.promo-description_payments {
  margin-top: 10px;
  margin-bottom: 50px;
  text-align: center;
  padding: 15px;
  background: rgba(17, 17, 18, 0.65);
}

.header-title-thre {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 42px;
  color: #f9f5e1;
}

.text-free {
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.5rem;
  color: #b8b8a2;
  text-align: initial;
}

.accordion {
  background-color: #191e23;
  color: #fff;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.accordion .section {
  width: 100%;
  height: inherit;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px 12px 0px 24px;
  color: #fff;
  font: bold 16px/58px Quantico, Arial, Helvetica, sans-serif;
  text-align: left;
  text-transform: uppercase;
  text-decoration: none;
}

.seller-page,
.general-page {
  position: relative;
  text-align: center;
}

.seller-page .container-marwrap,
.general-page .container-marwrap {
  margin-top: 30px;
  margin-bottom: 1rem;
}

.seller-page .block-info-sertificate,
.general-page .block-info-sertificate {
  height: 250px;
  background-color: rgba(39, 39, 43, 0.9);
  background-size: cover;
  background-position: 0 -150px;
}

.seller-page .block-info-sertificate h1,
.general-page .block-info-sertificate h1 {
  padding-top: 100px;
  font-weight: 700;
  position: relative;
  width: 68%;
  margin: 0 auto;
  font-size: 30px;
  line-height: 1.1;
  color: #fff;
  text-align: center;
}

.answered-block {
  position: relative;
  padding: 1rem 30px;
  background: #fff;
  color: #000;
  text-align: left;
}

.section-desciption-block {
  padding: 105px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #c3c9cc;
}

.hidden-xl-text {
  display: none;
  position: relative;
  width: 68%;
  margin: 0 auto;
  font-size: 30px;
  line-height: 1.1;
  color: #fff;
  text-align: center;
}

.desc-block-text {
  width: calc(100% - 200px);
  display: inline-block;
  font-size: 1rem;
  line-height: 25px;
  text-align: left;
}

.hidden-sm-text {
  width: 100%;
  margin: 0 0 10px;
  color: #000000;
  text-align: left;
  font-weight: bold;
}

.p-default {
  padding-right: 50px;
}

.section-desciption-block .section-desciption-block-right {
  padding: 105px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #c3c9cc;
}

.desc-block-icon {
  position: relative;
}

.info-block-after {
  margin: 30px 0;
  padding: 36px 60px;
  background: #fff;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5) inset, 0 1px 2px rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5) inset, 0 1px 2px rgba(255, 255, 255, 0.9);
  color: #000;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}

.panel {
  padding: 1rem 18px;
  overflow: hidden;
  text-align: left;
}

.header-title-thre a {
  color: #f25322;
  text-decoration: none;
}

.header-title-thre a:hover {
  color: #fa7f5a;
}

.chat {
  width: 475px;
  height: 552px;
  position: absolute;
  background-color: #312e2e;
  border-radius: 10px;
  position: fixed;
  z-index: 100;
  bottom: 2.43em;
  right: 1.4285714286em;
  box-shadow: 0 5px 38px rgb(0 0 0 / 15%);
  cursor: pointer;
  -webkit-transition: visibility 0s linear 0.2s, opacity linear 0.2s;
  -o-transition: visibility 0s linear 0.2s, opacity linear 0.2s;
  transition: visibility 0s linear 0.2s, opacity linear 0.2s;
  transition: all 1.5s !important;
  border: 1.5px solid #f25322;
  opacity: 0;
  overflow: visible;
}

.form-control {
  width: 80% !important;
}

#inputTextSumma {
  width: 60% !important;
}

.chat-header {
  height: 70px;
  background: rgba(17, 17, 18, 0.65);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 0.5px solid #f25322;
  padding: 1rem;
  display: flex;
  align-items: center;
}

.outputBlock {
  width: 82%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.img-helper {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1.5px solid bisque;
}

.helper-nickName {
  margin-left: 10px;
  color: #d7ebe7;
}

.fade-in-out {
  animation: fadeInOut 10s ease-in-out infinite;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  15% {
    opacity: 0.6;
  }
  25% {
    opacity: 0.8;
  }
  35% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.activ-chat {
  opacity: 1;
  transition: all 0.5s !important;
}

.bell-color {
  color: red;
  animation-duration: 0.2s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  transition: all 0.3s;
  cursor: pointer;
}

@keyframes slidein {
  from {
    transition: all 0.3s;
    transform: rotate(5deg);
  }

  to {
    transition: 0.3s;
    transform: rotate(-5deg);
  }
}
.logW {
  width: 100% !important;
}

.message-header {
  background: rgba(17, 17, 18, 0.65);
  border-radius: 15px;
  padding: 15px;
  color: #f9f5e1;
  display: flex;
  justify-content: space-between;
}

.message-header h2 {
  margin: 0;
}

.message-body {
  margin-top: 10px;
  background: rgba(17, 17, 18, 0.65);
  border-radius: 15px;
  padding: 25px;
  color: #f9f5e1;
  font-size: 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.heiggg {
  min-height: 100vh;
}

.flex-info-block {
  display: flex;
  color: white;
  box-shadow: inset;
  padding: 6px;
  background: rgba(17, 17, 18, 0.65);
}

.chat-bg {
  position: fixed;
  bottom: 0.43em;
  right: 1.4285714286em;
  width: 100%;
  height: 100vh;
  z-index: 10;
}

.flex-cont {
  display: flex;
  padding-top: 100px;
}

.block_user_panel {
  padding: 10px;
  border: 0;
  margin-top: 10px;
  cursor: pointer;
  background: 0;
  color: #f9f5e1;
}

.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  /* background-image: url(../image/div.png); */
  background-color: #191919 !important;
}

.activ-block-admin {
  background-color: #2e2d2d;
}

.block_user_panel:hover {
  border-color: #36d7b7;
  color: #36d7b7;
  transform: scale(1.04);
}

.block_user_panel h4 {
  margin: 0;
}

.panel_user {
  background: rgba(17, 17, 18, 0.65);
  color: #f9f5e1;
  padding: 1rem;
  width: 87%;
}

.tabl-flex {
  display: flex;
  justify-content: space-evenly;
  background-color: #f25322;
  color: #fff;
  padding: 10px;
}

.carousel-inner {
  padding: 0 !important;
}

@media (max-width: 767px) {
  .section-check-info {
    padding: 10px 15px;
  }

  .list-clip li {
    padding: 0 0 0 1rem;
    font-size: 12px;
  }

  .gen-text {
    width: 90% !important;
    font-size: 27px !important;
  }

  .block-info-sertificate {
    background-position: 0 0 !important;
  }

  .hidden-sm-text {
    display: none !important;
  }

  .p-default {
    padding: 0px !important;
    width: 100% !important;
  }

  .desc-block-text {
    width: 100% !important;
    text-align: center !important;
    font-size: 14px !important;
    margin-bottom: 10px !important;
    line-height: 18px !important;
  }

  .desc-block-icon {
    text-align: center !important;
    margin-bottom: 1rem !important;
  }

  .section-desciption-block {
    padding: 30px 0 25px !important;
    display: block !important;
  }

  .hidden-xl-text {
    width: 100% !important;
    display: block !important;
    margin-bottom: 40px !important;
    color: #000000 !important;
    font-size: 18px !important;
    font-weight: 700 !important;
  }

  .info-block-after {
    padding: 15px 30px !important;
    font-size: 16px !important;
    text-align: left !important;
    line-height: 25px !important;
  }

  .main-section__banner {
    padding-top: 55% !important;
  }

  .main-section__heading {
    max-width: 298px !important;
    padding: 10px 15px 15px !important;
    left: 30px !important;
    font-size: 14px !important;
  }

  .main-section--about {
    margin-bottom: 110px !important;
  }

  .step-wrap {
    padding-bottom: 25px !important;
  }

  .step-wrap__item {
    width: 50% !important;
    padding: 0 10px !important;
  }

  .status-wrap {
    padding: 0 10px !important;
  }

  .status-wrap__heading {
    font-size: 14px !important;
    padding: 10px !important;
  }

  .info-list {
    padding: 10px !important;
  }

  .card-info {
    font-size: 12px !important;
  }

  .card-info__heading {
    padding: 0 5px !important;
    flex: 0 0 110px !important;
  }

  .card-info__content {
    padding: 5px 10px !important;
  }

  .protection-period {
    padding: 0 10px !important;
  }

  .protection-period__heading {
    padding: 10px !important;
    margin: 0 0 25px !important;
    font-size: 14px !important;
  }

  .protection-period__description {
    font-size: 13px !important;
    max-width: 820px !important;
    padding: 10px 35px !important;
  }

  .banner-info__description {
    font-size: 12px !important;
    max-width: 240px !important;
    padding: 10px 15px !important;
  }

  .banner-info__img-wrap {
    max-width: 300px !important;
    margin: 0 auto -22px !important;
  }

  .containerCard {
    max-width: 362px !important;
  }

  .chat {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #312e2e;
    border-radius: 10px;
    position: fixed;
    z-index: 100;
    bottom: 0;
    right: 1.4285714286em;
    box-shadow: 0 5px 38px rgb(0 0 0 / 15%);
    cursor: pointer;
    transition: visibility 0s linear 0.2s, opacity linear 0.2s;
    transition: ease 1.5s !important;
    border: 1.5px solid #f25322;

    right: 0;
  }

  .activ-chat {
    transition: all 0.5s !important;
  }

  .outputBlock {
    width: 50%;
  }

  .footer_holder {
    display: flex;
    flex-direction: column;
  }

  .user-profile-block {
    margin-left: -98px;
  }

  .flex-adapt-makedal {
    display: flex;
    flex-direction: column !important;
  }

  .list-promo-item {
    display: block;
  }

  .dilit-block {
    display: none !important;
  }

  .form-operation__item {
    width: 335px !important;
  }

  .operation__heading {
    margin-top: 1rem;
  }

  .form-operation {
    padding: 10px;
  }

  .form-select {
    width: 100% !important;
  }

  .form-operation__item {
    margin: 0;
  }

  .form-operation {
    flex-direction: column;
  }

  .height-box {
    padding: 8px;
    margin-top: 1rem;
    height: 90vh;
  }

  .flex-box-0 {
    min-height: 100%;
  }

  .flex-box-1 {
    min-height: 100%;
  }

  .nav-account__content {
    padding: 0;
  }

  .account-wrap__about-info {
    margin: 0 !important;
  }

  .nav-account__link {
    height: 90px;
    padding: 0;
  }

  .nav-account__link:not(:first-child) {
    margin-top: 0;
  }

  .nav-account__tabs {
    display: flex;
    flex: 0 0 120px;
  }

  .nav-account {
    display: flex;
    flex-direction: column;
  }

  .account-wrap__heading {
    padding: 8px !important;
    flex-direction: column;
    margin-bottom: 1rem !important;
  }

  .heiggg {
    min-height: 80vh;
  }

  .show-img-1 {
    width: 350px !important;
    height: 185px !important;
  }

  .message-header {
    display: block;
  }

  .width-form {
    width: 367px;
  }

  .form-control {
    width: 100% !important;
  }

  .login-inner_title {
    font-size: 22px !important;
    padding: 8px;
  }

  .message-flex {
    font-size: 22px !important;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .promo-description {
    padding: 1rem !important;
  }

  .list-steps {
    padding: 0;
  }

  .promo-steps {
    padding: 1rem !important;
  }

  .promo-steps h3 {
    margin-bottom: 1rem;
  }

  .promote-slider_holder {
    padding: 8px;
  }

  .promote-slider_list {
    padding: 0;
    margin: 0;
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }

  .info-card {
    margin: 0;
    width: 350px;
  }

  .promote-slider_item_list {
    gap: 1rem;
  }

  .block-text-free {
    padding: 8px;
  }

  .message-body {
    margin: 0 !important;
    padding: 8px;
  }

  .message-header {
    margin: 0 !important;
    padding: 8px;
  }

  .alert-block {
    max-width: 365px !important;
    margin-right: 1rem;
  }

  .carousel-item {
    flex-direction: column;
  }
}

@media (max-width: 670px) {
  .promote-slider_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }
}

@media (max-width: 916px) {
  .buttonDeal2 {
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(5.5px);
    background: rgb(217, 20, 20);
    border: 1px solid rgb(217, 20, 20);
    padding: 2px 5px;
    text-align: center;
    box-shadow: 0 4px 30px rgba(35, 35, 35, 0.1);
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    align-items: center;
    font-family: inherit;
    max-width: 220px;
    cursor: pointer;
    border-radius: 5px;
    color: rgba(255, 255, 255, 0.879);
  }

  .buttonDeal1 {
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(5.5px);
    background: rgb(45, 182, 18);
    border: 1px solid rgb(49, 195, 20);
    padding: 2px 5px;
    text-align: center;
    box-shadow: 0 4px 30px rgba(35, 35, 35, 0.1);
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    align-items: center;
    font-family: inherit;
    max-width: 220px;
    cursor: pointer;
    border-radius: 5px;
    color: rgba(255, 255, 255, 0.879);
  }

  .banner-info__img-wrap {
    max-width: 420px;
    margin: 0 auto -30px;
  }

  .carousel-control-prev {
    display: none !important;
  }

  .carousel-control-next {
    display: none !important;
  }

  .carousel-indicators {
    display: none !important;
  }

  .containerCard {
    max-width: 350px;
  }

  .chat {
    width: 375px;
    height: 452px;
    position: absolute;
    background-color: #312e2e;
    border-radius: 10px;
    position: fixed;
    z-index: 100;
    bottom: 0;
    right: 1.4285714286em;
    box-shadow: 0 5px 38px rgb(0 0 0 / 15%);
    cursor: pointer;
    transition: visibility 0s linear 0.2s, opacity linear 0.2s;
    transition: ease 1.5s !important;
    border: 1.5px solid #f25322;
    max-height: 0;
    overflow: hidden;
    right: 0;
  }

  .activ-chat {
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: visible;
    transition: all 1.2s !important;
  }

  .nav-account__tabs {
    display: flex;
    justify-content: space-between;
  }

  .height-box {
    padding: 9px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .list-steps {
    padding: 8px;
  }

  .navbar-header {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .footer-menu {
    padding: 0;
  }

  .width-form {
    padding: 8px;
  }

  .promote-slider_item {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    gap: 23px;
  }

  .info-card {
    margin: 0;
  }

  .login-inner_title {
    padding: 8px;
  }

  .flex-adapt {
    display: flex;
    flex-direction: column;
  }

  .show-img-1 {
    width: 260px;
    height: 160px;
  }

  .promote-slider_list {
    display: flex;
    justify-content: center;
    padding: 8px;
    margin: 0;
    gap: 23px;
  }

  .block-text-free {
    padding: 8px;
  }

  .promote-slider_holder {
    padding: 8px;
  }

  .message-header {
    margin-left: 50px;
    margin-right: 50px;
  }

  .message-body {
    margin-left: 50px;
    margin-right: 50px;
  }

  .nav-account {
    display: flex;
    flex-direction: column;
  }

  .nav-account__tabs {
    margin-bottom: 1rem;
  }

  .nav-account__tabs {
    flex: 0;
  }

  .nav-account__link:not(:first-child) {
    margin-top: 0px;
  }

  .flex-info-block {
    justify-content: space-around;
  }

  .alert-block {
    width: 790px;
  }
}

@media (max-width: 1100px) {
  .footer_holder {
    display: flex;
    gap: 10px;
  }

  .footer-menu {
    padding: 0;
  }

  .show-img-1 {
    width: 258px;
    height: 150px;
  }

  .width-form {
    padding: 8px;
  }

  .login-inner_title {
    padding: 8px;
  }
}

@media (max-width: 414px) and (min-width: 400px) {
  .containerCard {
    max-width: 400px !important;
  }

  .promote-slider_list {
    display: flex;
    flex-direction: column;
  }

  .show-img-1 {
    width: 370px;
    height: 190px;
  }

  .info-card {
    width: 374px;
  }

  .promote-slider_list {
    padding: 0;
  }
  .alert-block {
    max-width: 390px !important;
  }
}

@media (max-width: 375px) {
  .alert-block {
    max-width: 350px !important;
  }

  .form-operation {
    align-items: center;
  }

  .ist-promo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .list-promo-item {
    min-width: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
